import React from "react";
import "./App.css";
// import Navigation from "./components/Navigation";
import Nav from "./components/Nav";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <Navigation /> */}
        <Nav />
      </header>
    </div>
  );
}

export default App;
